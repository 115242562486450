import { VerifiedUserRounded } from '@mui/icons-material';
import { user as userApi } from '../../../api';
import { MIN_PAGINATION_FILTER } from '../../../constants';
import { useApi, useInternationalization } from '../../../hooks';
import { routes } from '../../../routes';
import { Styles, UserFilter, UserType } from '../../../types';
import { CounterWidget } from './CounterWidget';
import { WidgetProps } from './Widget';

const styles: Styles = {
  container: {
    maxWidth: '100%',
    width: 'auto',
    mb: 2,
  },
};

const filter: UserFilter = {
  isVerified: false,
  isArchived: false,
  ...MIN_PAGINATION_FILTER,
};

export const UnverifiedMemberUser = (props: WidgetProps) => {
  const { t } = useInternationalization();
  const { data, isLoading } = useApi(userApi.getAll, null, UserType.Member, filter);

  return (
    <CounterWidget
      {...props}
      isLoading={isLoading}
      count={data?.totalCount}
      title={t('dashboard:unverifiedMemberUser:title')}
      icon={VerifiedUserRounded}
      link={routes.Member.User.path}
      sx={styles.container}
    />
  );
};
